import React from 'react';
import { motion } from 'framer-motion';

const MotivationalSection = () => {
    const phrases = [
        {
          text: "¡El único límite eres tú mismo!",
          img: "https://images.pexels.com/photos/1552249/pexels-photo-1552249.jpeg",
        },
        {
          text: "Transforma tu cuerpo, transforma tu mente.",
          img: "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg",
        },
        {
          text: "Cada día es una nueva oportunidad para mejorar.",
          img: "https://images.pexels.com/photos/3757374/pexels-photo-3757374.jpeg",
        },
        {
          text: "No pares cuando estés cansado, para cuando hayas terminado.",
          img: "https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg",
        },
        {
          text: "El esfuerzo de hoy será tu orgullo mañana.",
          img: "https://images.pexels.com/photos/2261485/pexels-photo-2261485.jpeg",
        },
      ];
      

  return (
    <section
      className="relative py-20 bg-cover bg-center text-light"
      style={{
        backgroundImage: "url('https://images.pexels.com/photos/1552249/pexels-photo-1552249.jpeg')",
      }}
    >
      {/* Capa oscura */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-dark to-black bg-opacity-80"></div>

      {/* Contenido */}
      <div className="relative container mx-auto px-6">
        {/* Título */}
        <h2 className="text-5xl md:text-6xl font-extrabold text-center text-primary mb-16 tracking-wide">
          ¡Motívate con nosotros!
        </h2>

        {/* Frases e Imágenes */}
        <div className="space-y-16">
          {phrases.map((item, index) => (
            <motion.div
              key={index}
              className={`flex flex-col md:flex-row items-center ${
                index % 2 === 0 ? "md:flex-row-reverse" : ""
              } space-y-6 md:space-y-0 md:space-x-10`}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              {/* Imagen */}
              <div className="w-full md:w-1/2">
                <img
                  src={item.img}
                  alt={item.text}
                  className="w-full rounded-2xl shadow-xl hover:shadow-2xl transition-transform transform hover:scale-105"
                />
              </div>

              {/* Frase */}
              <div className="w-full md:w-1/2 text-center md:text-left">
                <p className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-primary to-orange-500 bg-clip-text text-transparent py-6 px-4 rounded-lg hover:scale-105 transition-transform">
                  {item.text}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MotivationalSection;
