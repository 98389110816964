import React from "react";

const DashboardSidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setActiveSection,
}) => {
  const links = [
    { name: "Vista General", section: "overview" },
    { name: "Calendario", section: "calendar" },
    { name: "Mis Reservas", section: "reservations" },
  ];

  return (
    <aside
      className={`fixed z-20 inset-y-0 left-0 w-64 bg-gray-900 text-gray-300 transform ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 lg:translate-x-0`}
    >
      <div className="p-6">
        <h2 className="text-3xl font-bold text-orange-400">MediaFit</h2>
      </div>
      <nav className="space-y-4 px-6">
        {links.map((link) => (
          <button
            key={link.section}
            onClick={() => {
              setActiveSection(link.section);
              setIsSidebarOpen(false);
            }}
            className="block w-full text-left py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-black transition"
          >
            {link.name}
          </button>
        ))}
      </nav>
    </aside>
  );
};

export default DashboardSidebar;
