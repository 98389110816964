import React, { useState } from "react";
import DashboardSidebar from "../components/DashboardSidebar";
import DashboardHeader from "../components/DashboardHeader";
import DashboardOverview from "../components/DashboardOverview";
import Calendar from "../components/Calendar";
import ReservationsList from "../components/ReservationsList";
import AdminControls from "../components/AdminControls";

const Dashboard = ({ isAdmin }) => {
  const [activeSection, setActiveSection] = useState("overview");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 text-gray-300">
      {/* Sidebar */}
      <DashboardSidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setActiveSection={setActiveSection}
      />

      {/* Contenido Principal */}
      <div className="flex-1 flex flex-col">
        <DashboardHeader setIsSidebarOpen={setIsSidebarOpen} />

        <main className="p-6 space-y-6">
          {activeSection === "overview" && <DashboardOverview />}
          {activeSection === "calendar" && <Calendar />}
          {activeSection === "reservations" && (
            <ReservationsList
              reservations={mockReservations}
              isAdmin={isAdmin}
              onCancel={(id) => console.log("Cancelar reserva", id)}
            />
          )}
          {activeSection === "admin" && isAdmin && <AdminControls />}
        </main>
      </div>
    </div>
  );
};

const mockReservations = [
  {
    id: 1,
    className: "Yoga",
    date: "2024-11-22",
    time: "10:00",
    clientName: "Juan Pérez",
  },
  {
    id: 2,
    className: "Spinning",
    date: "2024-11-23",
    time: "18:00",
    clientName: "María López",
  },
];

export default Dashboard;
