import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const events = [
  {
    title: "Clase de Yoga",
    start: new Date(2024, 11, 22, 10, 0),
    end: new Date(2024, 11, 22, 11, 0),
  },
  {
    title: "Clase de Spinning",
    start: new Date(2024, 11, 23, 18, 0),
    end: new Date(2024, 11, 23, 19, 0),
  },
];

const CalendarComponent = () => {
  return (
    <section>
      <h2 className="text-xl font-bold text-dark mb-4">Calendario</h2>
      <div
        style={{ height: 500 }}
        className="bg-white p-4 rounded-lg shadow-md"
      >
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "100%" }}
        />
      </div>
    </section>
  );
};

export default CalendarComponent;
