import React from "react";
import { motion } from "framer-motion";

const UpcomingOpening = () => {
  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const logoVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1, transition: { duration: 0.8, delay: 0.5 } },
  };

  return (
    <section className="bg-gradient-to-br from-gray-900 to-black text-white py-16">
      <div className="container mx-auto px-6 text-center">
        {/* Logo Animado */}
        <motion.div
          className="mb-6"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={logoVariants}
        >
          <img
            src="/logo.png"
            alt="MediaFit Logo"
            className="w-32 mx-auto"
          />
        </motion.div>

        {/* Texto Animado */}
        <motion.h2
          className="text-4xl md:text-6xl font-extrabold text-orange-500 mb-4"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
        >
          ¡Próxima Apertura!
        </motion.h2>
        <motion.p
          className="text-lg md:text-xl text-gray-300 mb-8"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { delay: 1, duration: 1 } }}
          viewport={{ once: true }}
        >
          ¡Prepárate para una nueva experiencia fitness!
        </motion.p>

        {/* Botón Llamativo */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1, transition: { delay: 1.5 } }}
          viewport={{ once: true }}
        ></motion.div>
      </div>
    </section>
  );
};

export default UpcomingOpening;
