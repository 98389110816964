import React from "react";

const ReservationsList = ({ reservations, isAdmin, onCancel }) => {
  return (
    <section id="reservations" className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-bold text-dark mb-4">
        {isAdmin ? "Reservas de Clientes" : "Mis Reservas"}
      </h2>

      {reservations.length === 0 ? (
        <p className="text-gray-500">
          {isAdmin ? "No hay reservas registradas." : "Aún no tienes reservas."}
        </p>
      ) : (
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="text-left py-2 px-4 border">Clase</th>
              <th className="text-left py-2 px-4 border">Fecha</th>
              <th className="text-left py-2 px-4 border">Hora</th>
              {isAdmin && (
                <th className="text-left py-2 px-4 border">Cliente</th>
              )}
              <th className="text-center py-2 px-4 border">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {reservations.map((reservation) => (
              <tr key={reservation.id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border">{reservation.className}</td>
                <td className="py-2 px-4 border">{reservation.date}</td>
                <td className="py-2 px-4 border">{reservation.time}</td>
                {isAdmin && (
                  <td className="py-2 px-4 border">{reservation.clientName}</td>
                )}
                <td className="py-2 px-4 border text-center">
                  <button
                    onClick={() => onCancel(reservation.id)}
                    className="text-red-500 hover:underline"
                  >
                    Cancelar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};

export default ReservationsList;
