import React from "react";
import { motion } from "framer-motion";

const LoginUnderDevelopment = () => {
  const messageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const logoVariants = {
    hidden: { scale: 0 },
    visible: { scale: 1, transition: { duration: 0.8 } },
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
      <div className="text-center">
        {/* Logo con Animación */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={logoVariants}
          className="mb-8"
        >
          <img
            src="/logo.png"
            alt="MediaFit Logo"
            className="w-24 h-24 mx-auto"
          />
        </motion.div>

        {/* Mensaje Principal */}
        <motion.h1
          className="text-4xl md:text-5xl font-bold text-orange-500 mb-4"
          initial="hidden"
          animate="visible"
          variants={messageVariants}
        >
          ¡Estamos trabajando en ello!
        </motion.h1>

        {/* Mensaje Secundario */}
        <motion.p
          className="text-lg md:text-xl text-gray-300 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.5, duration: 1 } }}
        >
          Muy pronto podrás iniciar sesión y gestionar tus citas de
          entrenamiento.
        </motion.p>

        {/* Botón para Volver */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 1 } }}
        >
          <a
            href="/"
            className="py-3 px-8 bg-orange-500 text-black font-bold rounded-lg shadow-lg hover:bg-orange-400 transition"
          >
            Volver al Inicio
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default LoginUnderDevelopment;
