import React, { useState } from "react";

const ReservationsList = ({ reservations, isAdmin, onCancel }) => {
  const [search, setSearch] = useState("");
  const [filteredReservations, setFilteredReservations] =
    useState(reservations);

  // Filtrar reservas
  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filtered = reservations.filter((reservation) =>
      reservation.className.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredReservations(filtered);
  };

  return (
    <section id="reservations" className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-dark mb-6">
        {isAdmin ? "Reservas de Clientes" : "Mis Reservas"}
      </h2>

      {/* Buscador */}
      <div className="mb-4">
        <input
          type="text"
          value={search}
          onChange={handleSearch}
          placeholder="Buscar por clase..."
          className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-primary"
        />
      </div>

      {/* Tabla de Reservas */}
      {filteredReservations.length === 0 ? (
        <p className="text-gray-500">
          {isAdmin ? "No hay reservas registradas." : "Aún no tienes reservas."}
        </p>
      ) : (
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-left py-2 px-4 border">Clase</th>
              <th className="text-left py-2 px-4 border">Fecha</th>
              <th className="text-left py-2 px-4 border">Hora</th>
              {isAdmin && (
                <th className="text-left py-2 px-4 border">Cliente</th>
              )}
              <th className="text-left py-2 px-4 border">Ubicación</th>
              <th className="text-center py-2 px-4 border">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredReservations.map((reservation) => (
              <tr key={reservation.id} className="hover:bg-gray-50">
                <td className="py-2 px-4 border font-medium text-dark">
                  {reservation.className}
                </td>
                <td className="py-2 px-4 border">{reservation.date}</td>
                <td className="py-2 px-4 border">{reservation.time}</td>
                {isAdmin && (
                  <td className="py-2 px-4 border">{reservation.clientName}</td>
                )}
                <td className="py-2 px-4 border">
                  {reservation.location || "Gimnasio Central"}
                </td>
                <td className="py-2 px-4 border text-center">
                  <button
                    onClick={() =>
                      window.confirm(
                        "¿Estás seguro de cancelar esta reserva?"
                      ) && onCancel(reservation.id)
                    }
                    className="text-red-500 hover:underline"
                  >
                    Cancelar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};

export default ReservationsList;
