import React from "react";

const DashboardHeader = ({ setIsSidebarOpen }) => {
  return (
    <header className="bg-gray-900 shadow-md p-6 flex justify-between items-center">
      {/* Botón Hamburguesa (Solo en pantallas pequeñas) */}
      <button
        className="lg:hidden text-orange-500"
        onClick={() => setIsSidebarOpen((prev) => !prev)}
      >
        ☰
      </button>
      <h1 className="text-2xl font-bold">Dashboard</h1>
      <button className="py-2 px-4 bg-orange-500 text-black rounded-lg hover:bg-orange-400 transition">
        Cerrar Sesión
      </button>
    </header>
  );
};

export default DashboardHeader;
