import React from "react";

const DashboardOverview = () => {
  const stats = [
    { name: "Clases Reservadas", value: 12 },
    { name: "Clases Disponibles", value: 8 },
    { name: "Clientes Activos", value: 24 },
  ];

  return (
    <section className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="bg-gradient-to-b from-gray-700 to-gray-800 p-6 rounded-lg shadow-lg text-center hover:bg-gray-700 transition"
        >
          <h3 className="text-lg font-semibold text-gray-300">{stat.name}</h3>
          <p className="text-4xl font-extrabold text-orange-400">
            {stat.value}
          </p>
        </div>
      ))}
    </section>
  );
};

export default DashboardOverview;
