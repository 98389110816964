import React, { useState } from "react";
import MotivationalSection from "../components/MotivationalSection";
import UpcomingOpening from "../components/UpcomingOpening";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="min-h-screen flex flex-col bg-dark text-light">
      {/* Header */}
      <header className="relative">
        <div className="flex justify-between items-center px-8 py-4 bg-dark shadow-md z-10 relative">
          {/* Logo */}
          <div className="flex items-center">
            <img src="/logo.png" alt="MediaFit Logo" className="w-20" /> {/* Logo más grande */}
          </div>

          {/* Título centrado */}
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-4xl font-bold text-primary">
            MediaFit
          </h1>

          {/* Desktop Menu */}
          <div className="hidden md:flex">
            <button
              onClick={() => (window.location.href = "/login")}
              className="mr-4 px-4 py-2 text-sm bg-transparent text-light border border-primary rounded hover:bg-primary hover:text-dark transition"
            >
              Iniciar Sesión
            </button>
            <button
              onClick={() => (window.location.href = "/register")}
              className="px-4 py-2 text-sm bg-primary text-dark rounded hover:bg-orange-700 transition"
            >
              Registrarse
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-primary focus:outline-none"
              aria-label="Abrir menú"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isMenuOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Slider */}
 
      </header>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav className="md:hidden bg-dark text-light px-8 py-4 space-y-4 shadow-md">
          <button
            onClick={() => (window.location.href = "/login")}
            className="block w-full text-left px-4 py-2 text-sm bg-transparent text-light border border-primary rounded hover:bg-primary hover:text-dark transition"
          >
            Iniciar Sesión
          </button>
          <button
            onClick={() => (window.location.href = "/register")}
            className="block w-full text-left px-4 py-2 text-sm bg-primary text-dark rounded hover:bg-orange-700 transition"
          >
            Registrarse
          </button>
        </nav>
      )}
      {/* Hero Section */}
      <section
        className="flex items-center justify-center text-center bg-cover bg-center h-[60vh]"
        style={{
          backgroundImage: "url('/gymPhoto.jpeg')",
          backgroundPosition: "top center", // Ajustamos la posición vertical
        }}
      >
        <div className="bg-dark bg-opacity-60 p-8 rounded">
          <h2 className="text-4xl font-bold text-primary mb-4">
            ¡Pon tu cuerpo en movimiento!
          </h2>
          <p className="text-lg text-light mb-6">
            Entrenamientos personalizados y clases grupales diseñadas para ti.
          </p>
          <button
            onClick={() => (window.location.href = "/register")}
            className="px-6 py-3 bg-primary text-dark font-bold rounded hover:bg-orange-700 transition"
          >
            ¡Empieza Ahora!
          </button>
        </div>
      </section>
      <UpcomingOpening />
      {/* Motivational Section */}
      <MotivationalSection /> {/* Aquí agregamos la sección de frases */}
      {/* Servicios */}
      <section
        className="relative py-20 bg-cover bg-center"
        style={{
          backgroundImage: "url('/services.jpg')", // Ruta a la imagen de fondo
        }}
      >
        {/* Capa oscura para resaltar el contenido */}
        <div className="absolute inset-0 bg-dark bg-opacity-70"></div>

        {/* Contenido */}
        <div className="relative container mx-auto px-6 text-center text-light">
          <h3 className="text-4xl font-bold text-primary mb-12">
            Nuestros Servicios
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {/* Tarjeta 1 */}
            <div className="p-8 bg-white bg-opacity-90 rounded-lg shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
              <h4 className="text-2xl font-bold mb-4 text-dark">
                Entrenamiento Personalizado
              </h4>
              <p className="text-dark">
                Sesiones diseñadas para tus objetivos individuales.
              </p>
            </div>
            {/* Tarjeta 2 */}
            <div className="p-8 bg-white bg-opacity-90 rounded-lg shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
              <h4 className="text-2xl font-bold mb-4 text-dark">
                Clases Grupales
              </h4>
              <p className="text-dark">
                Tonificación, ciclo indoor y más actividades para mantenerte en
                forma.
              </p>
            </div>
            {/* Tarjeta 3 */}
            <div className="p-8 bg-white bg-opacity-90 rounded-lg shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
              <h4 className="text-2xl font-bold mb-4 text-dark">
                Horario Fitness 
              </h4>
              <p className="text-dark">
            Disfruta de la sala con reserva de horario
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Ubicación/Contacto */}
      <section className="py-12 bg-dark text-light">
        <div className="container mx-auto px-6 text-center">
          <h3 className="text-3xl font-bold text-primary mb-8">
            ¿Dónde estamos?
          </h3>
          <p className="mb-4">Avd. Antonio y Miguel Navarro, 14</p>
          <p className="mb-4">Montilla (frente Instituto Inca Garcilaso)</p>
          <p className="mb-4">
            Teléfono:{" "}
            <a href="tel:+34656393458" className="text-primary hover:underline">
              656 393 458
            </a>
          </p>
          <p>
            Instagram:{" "}
            <a
              href="https://instagram.com/mediafit_"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline"
            >
              @mediafit_
            </a>
          </p>
        </div>
      </section>
      {/* Footer */}
      <footer className="py-4 bg-dark text-sm text-light">
  <div className="container mx-auto px-6 flex flex-wrap justify-between items-center">
    {/* Información principal */}
    <p className="flex-grow text-center md:text-left">
      © {new Date().getFullYear()} MediaFit - Todos los derechos reservados.
    </p>

    {/* Enlaces adicionales */}
    <div className="flex space-x-6">
      <a
        href="/terms"
        className="hover:text-primary transition"
      >
        Términos
      </a>
      <a
        href="/privacy"
        className="hover:text-primary transition"
      >
        Privacidad
      </a>
      <a
        href="/contact"
        className="hover:text-primary transition"
      >
        Contacto
      </a>
    </div>

    {/* Redes sociales */}
    <div className="flex space-x-6">
      <a
        href="https://instagram.com/mediafit_"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-primary transition"
      >
        Instagram
      </a>
      <a
        href="https://facebook.com/mediafit"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-primary transition"
      >
        Facebook
      </a>
    </div>
  </div>
</footer>


    </div>
  );
};

export default HomePage;
